<template>
  <div class="search">

    <el-card style="margin-bottom:12px">
      <div class="flex">
        <div class="cTitle" @click="more = !more">
          <i v-if="!more" class="el-icon-caret-right"></i>
          <i v-if="more" class="el-icon-caret-bottom"></i>


          高级筛选
        </div>
        <div class="btnAction">
          <el-input v-model="searchForm.compName" placeholder="请输入供应商名称" style="width:160px;margin-right:8px"></el-input>
          <el-button type="primary" plain @click="getDataList" size="mini">搜索</el-button>
        </div>
      </div>
      <div class="flex" style="margin-top: 10px;" v-if="more">
        <el-input v-model="searchForm.contractName" placeholder="请输入联系人" style="width:160px;margin-right:8px"></el-input>
        <el-input v-model="searchForm.contractPhone" placeholder="请输入联系方式"
          style="width:160px;margin-right:8px"></el-input>
      </div>
    </el-card>

    <el-card>
      <div class="flex">
        <div class="cTitle">供应商管理</div>
        <div class="btnAction">
          <el-button type="primary" plain round @click="add" size="mini">添加供应商</el-button>
          <el-button type="primary" plain round @click="delAll" size="mini">批量删除</el-button>
        </div>
      </div>
      <div style="margin-top:16px">

        <vxe-table ref="table" align="center" :export-config="{
          types: ['csv'],
          isHeader: true,
        }" :print-config="{}" stripe size="medium" :height="height - 147 - 50 - 95" :loading="loading"
          :column-config="{ resizable: true }" :row-config="{ isHover: true }" :data="data">
          <vxe-column fixed="left" type="checkbox" width="40"></vxe-column>

          <vxe-column field="compName" title="供应商名称">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.compName }}</span>
            </template>
          </vxe-column>

          <vxe-column field="shortName" title="供应商简称">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.shortName }}</span>
            </template>
          </vxe-column>

          <vxe-column field="buildName" title="物料列表">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>
                <div style="cursor: pointer;" @click="settingGoodsItem(row)">
                  <el-button style="color:red" v-if="row.goodItems.length == 0" size="mini" type="text">点击设置</el-button>
                  <el-button v-else size="mini" type="text">已设置【{{ row.goodItems.length }}】条</el-button>
                </div>
              </span>
            </template>
          </vxe-column>
          <vxe-column field="buildName" title="联系人">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.contractName }}</span>
            </template>
          </vxe-column>
          <vxe-column field="buildName" title="联系方式">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <span>{{ row.contractPhone }}</span>
            </template>
          </vxe-column>

          <vxe-column field="buildName" title="卸货人员" widh="100">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <div style="cursor: pointer;" @click="selectUser(row, 0)">
                <el-button style="color:red" v-if="!row.unloadUser" size="mini" type="text">点击设置</el-button>
                <el-button v-else size="mini" type="text">
                  <div v-for="items in row.unloadUser">
                    {{ items.nickName }} - {{ items.tel }}
                  </div>
                </el-button>
              </div>
            </template>
          </vxe-column>


          <vxe-column field="buildName" title="验收人员" widh="100">
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
              <div style="cursor: pointer;" @click="selectUser(row, 1)">
                <el-button style="color:red" v-if="!row.checkUser" size="mini" type="text">点击设置</el-button>
                <el-button v-else size="mini" type="text">
                  <div v-for="items in row.checkUser">
                    {{ items.nickName }} - {{ items.tel }}
                  </div>
                </el-button>
              </div>
            </template>
          </vxe-column>
          <!-- <vxe-column field="createTime" title="创建时间" >
            <template #header="{ column }">
              <span style="color: #909399">{{ column.title }}</span>
            </template>
            <template #default="{ row }">
            <span>{{row.createTime}}</span>
            </template>
          </vxe-column> -->
          <!--        <vxe-column field="yjFinishTime" title="预期完成时间" width="250"></vxe-column>-->
          <vxe-column fixed="right" title="操作" width="230">
            <template #default="{ row }">
              <div>
                <el-button size="mini" plain type="primary" @click="highSettingClick(row)">车辆限制</el-button>
                <el-button size="mini" plain type="primary" @click="edit(row)">编辑</el-button>
                <el-button size="mini" plain type="danger" @click="remove(row)">删除</el-button>
              </div>

            </template>
          </vxe-column>
          <vxe-column fixed="right" width="8" />
        </vxe-table>
        <vxe-pager :loading="loading" :current-page="page.currentPage" :page-size="page.pageSize" :total="page.total"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
          @page-change="handlePageChange1">
        </vxe-pager>



        <addEdit :data="form" :type="showType" v-model="showDrawer" @on-submit="getDataList" />
      </div>

      <el-dialog :visible.sync="highSetting" :close-on-click-modal="false" title="每日车辆次数限制" width="606px">


        <el-switch v-model="dayConfigCheck" />
          <div style="margin-top: 8px;" v-if="dayConfigCheck">
            <span>每日车辆次数:</span>
            <Input  v-model="dayCount" type="number" style="width: 200px;"  placeholder="每日车辆次数" clearable/>
          </div>
        

        <div slot="footer" class="dialog-footer">

          <el-button
          type="primary"
          size="small"
          :loading="loading"
          @click="highSettingSubmit('day')"
         >确认设置
        </el-button>
      
        <el-button
          type="default"
          size="small"
          @click="highSetting = false">取消
        </el-button>

        </div>


      </el-dialog>


      <el-dialog :visible.sync="dialogGooditemsVisible" :close-on-click-modal="false" title="设置物料信息" width="606px">
        <div>
          <el-transfer filterable filter-placeholder="请输入搜索内容" v-model="goodItemSelect" :titles="goodItemTitle"
            :data="goodItemData">
          </el-transfer>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" :loading="loading" @click="updateSettingGoodItems">更 新
          </el-button>
          <el-button type="default" size="small" @click="dialogGooditemsVisible = false">取消
          </el-button>
        </div>

      </el-dialog>



      <el-dialog :visible.sync="dialogSelectUser" :close-on-click-modal="false" title="选择用户" width="576px">
        <div style="margin-top: 20px">
          <el-row>
            <el-checkbox-group v-model="checkUserId" size="mini">
              <el-col :span="8" v-for="item in checkUserData">
                <el-checkbox :label="item.id" style="margin: 4px;" border>
                  {{ item.nickName }} - {{ item.tel }}
                </el-checkbox>
              </el-col>
            </el-checkbox-group>
          </el-row>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="small" :loading="loading" @click="submitCheckUser">确认选择
          </el-button>

          <el-button type="default" size="small" @click="dialogSelectUser = false">取消
          </el-button>
        </div>

      </el-dialog>


    </el-card>


  </div>
</template>

<script>
// 根据你的实际请求api.js位置路径修改
import {
  getCompanyList,
  deleteCompany,
  getGoodItemsAll,
  addCompayGoodNames,
  getCheckUserList,
  editCompany, updateCompanyDayCount
} from "@/api/index";
// 根据你的实际添加编辑组件位置路径修改
import addEdit from "./addEdit.vue";
import { shortcuts } from "@/libs/shortcuts";
export default {
  name: "buildingConfig",
  components: {
    addEdit,
  },
  data() {
    return {
      dayCount:99,
      highSetting: false,
      dayConfigCheck: false,

      more: false,
      dialogSelectUser: false,
      checkUserData: [],
      checkUserId: [],
      userType: 0,
      //0
      dialogGooditemsVisible: false,
      goodItemSelect: [],
      goodItemTitle: [
        "可选择", "已选择"
      ],
      goodItemData: [],
      selectItem: {},
      loading: false,



      //2
      height: 600,
      tableSize: "default",
      openTip: true, // 显示提示
      showType: "0", // 添加或编辑标识
      showDrawer: false, // 显示添加编辑抽屉
      loading: true, // 表单加载状态
      searchForm: { // 搜索框初始化对象
        pageNumber: 1, // 当前页数
        pageSize: 10, // 页面大小
        sort: "createTime", // 默认排序字段
        order: "desc", // 默认排序方式
      },
      selectList: [], // 多选数据
      form: {},
      data: [], // 表单数据
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },

    };
  },
  methods: {

    highSettingSubmit(v){
      let selectItem = this.selectItem
      let dayConfigCheck = this.dayConfigCheck
      let dayCount = this.dayCount
      let obj = {
        id:selectItem.id,
        dayEnable:dayConfigCheck?1:0,
        dayCount:dayCount
      }
  

      updateCompanyDayCount(obj).then(res=>{
        this.highSetting  = false
        this.$Message.success("操作成功");
        this.getDataList()
      })


    },  

    highSettingClick(v) {

      this.selectItem = v
      if (v.dayControlFlag == 1){
        this.dayConfigCheck = true;
        if (v.dayControlCount){
          this.dayCount = v.dayControlCount
        } else {
          this.dayCount = 99
        }
       
      } else {
        this.dayConfigCheck = false;
        this.dayCount = 99
      }
      this.highSetting = true


    },
    submitCheckUser(v) {

      delete this.selectItem.tvsCompanyUser
      delete this.selectItem.tvsCompanyGoodNames
      delete this.selectItem.goodItems
      delete this.selectItem.unloadUser
      delete this.selectItem.checkUser

      if (this.userType == 0) {
        this.selectItem.unloadUserId = this.checkUserId.join()
      } else {
        this.selectItem.checkUserId = this.checkUserId.join()
      }
      this.loading = true
      editCompany(this.selectItem).then(res => {
        this.loading = false
        this.dialogSelectUser = false
        this.getDataList()
      }).catch(res => {
        this.loading = false
      })

    },
    selectUser(row, userType) {
      this.userType = userType

      for (let attr in row) {
        if (row[attr] == null) {
          row[attr] = "";
        }
      }
      let str = JSON.stringify(row);
      let data = JSON.parse(str);
      this.selectItem = data


      if (userType == 0) {
        if (row.unloadUser && row.unloadUser.length > 0) {
          let array = []
          for (var i = 0; i < row.unloadUser.length; i++) {
            array.push(row.unloadUser[i].id)
          }
          this.checkUserId = array
        } else {
          this.checkUserId = []
        }

      } else {
        if (row.checkUser && row.checkUser.length > 0) {
          let array = []
          for (var i = 0; i < row.checkUser.length; i++) {
            array.push(row.checkUser[i].id)
          }
          this.checkUserId = array
        } else {
          this.checkUserId = []
        }
      }


      getCheckUserList(userType).then(res => {
        let result = res.result
        if (result.length > 0) {
          this.checkUserData = result
          this.dialogSelectUser = true
        } else {
          this.$notify.warning("未设置人员信息")
        }

      })

    },

    updateSettingGoodItems() {
      let data = this.goodItemSelect
      let item = this.selectItem
      this.loading = true
      addCompayGoodNames({
        goodItems: data,
        compId: item.id
      }).then(res => {
        this.dialogGooditemsVisible = false
        this.getDataList();
      })
    },
    settingGoodsItem(item) {

      for (let attr in item) {
        if (item[attr] == null) {
          item[attr] = "";
        }
      }
      let str = JSON.stringify(item);
      let data = JSON.parse(str);
      this.selectItem = data
      this.selectItem = item
      getGoodItemsAll().then(res => {

        let selectGoodData = item.goodItems
        let array = res.result
        let m = []
        let goodItemSelect = []
        for (var i = 0; i < array.length; i++) {
          m.push({
            label: array[i],
            key: array[i],
            disabled: false
          })
          for (var j =0;j<selectGoodData.length;j++){
            if (array[i] === selectGoodData[j]){
              goodItemSelect.push(array[i])
              break;
            }
          }
        }
        this.goodItemSelect = goodItemSelect
        this.goodItemData = m
        this.dialogGooditemsVisible = true

      })
    },

    init() {
      this.getDataList();
    },
    handlePageChange1({ currentPage, pageSize }) {
      this.page.currentPage = currentPage
      this.page.pageSize = pageSize
      this.getDataList()
    },
    getDataList() {
      this.loading = true;

      getCompanyList(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize
      }, this.searchForm)).then(res => {
        this.loading = false;
        if (res.success) {
          this.data = res.result.records;
          this.page.total = res.result.total
        }
      });
    },
    add() {
      this.showType = "2";
      this.showDrawer = true;
    },
    edit(v) {
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.form = data;
      this.showType = "1";
      this.showDrawer = true;
    },
    remove(v) {
      this.$Modal.confirm({
        title: "确认删除",
        // 记得确认修改此处
        content: "您确认要删除吗?",
        loading: true,
        onOk: () => {
          // 删除
          deleteCompany({ ids: v.id }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.getDataList();
            }
          });
        }
      });
    },
    delAll() {
      let selectRecords = this.$refs.table.getCheckboxRecords()
      if (selectRecords.length <= 0) {
        this.$Message.warning("您还未选择要删除的数据");
        return;
      }
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除所选的 " + selectRecords.length + " 条数据?",
        loading: true,
        onOk: () => {
          let ids = "";
          selectRecords.forEach(function (e) {
            ids += e.id + ",";
          });
          ids = ids.substring(0, ids.length - 1);
          // 批量删除
          deleteCompany({ ids: ids }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.getDataList();
            }
          });
        }
      });
    }
  },
  mounted() {
    this.init();

    this.height = (window.innerHeight)
    window.onresize = () => {
      return (() => {
        this.height = (window.innerHeight)
      })()
    }
  }
};
</script>
<style lang="less">
@import "@/styles/table-common.less";

.flex {
  display: flex;
}

.cTitle {
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 800;
}

.btnAction {
  flex: 1;
  text-align: right;
}

.vxe-pager.is--perfect .vxe-pager--num-btn:not(.is--disabled).is--active {
  background-color: #b6986d;
}

.is--checked.vxe-checkbox,
.is--checked.vxe-checkbox .vxe-checkbox--icon,
.is--checked.vxe-custom--option,
.is--checked.vxe-custom--option .vxe-checkbox--icon,
.is--checked.vxe-export--panel-column-option,
.is--checked.vxe-export--panel-column-option .vxe-checkbox--icon,
.is--checked.vxe-table--filter-option,
.is--checked.vxe-table--filter-option .vxe-checkbox--icon,
.is--indeterminate.vxe-checkbox,
.is--indeterminate.vxe-checkbox .vxe-checkbox--icon,
.is--indeterminate.vxe-custom--option,
.is--indeterminate.vxe-custom--option .vxe-checkbox--icon,
.is--indeterminate.vxe-export--panel-column-option,
.is--indeterminate.vxe-export--panel-column-option .vxe-checkbox--icon,
.is--indeterminate.vxe-table--filter-option,
.is--indeterminate.vxe-table--filter-option .vxe-checkbox--icon,
.vxe-table--render-default .is--checked.vxe-cell--checkbox,
.vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon,
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox,
.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon {
  color: #b6986d
}
</style>